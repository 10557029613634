*{
    font-family: 'Roboto', monospace;
  }

  p{
    font-size: 1.2rem;
  }

  .App{
    min-height: 100vh;
  }

  .bg{
    background-color: #060A11;
  }

.dragDrop{
    width: -webkit-fill-available;
    text-align: center;
    cursor: pointer;
}

.file-container{
    width: -webkit-fill-available;
    height: 75vh;
}

.file-container img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.file-container iframe{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.file-container .gen-text{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navbar ul li a{
    color: #ffffff;
    font-weight: bold;
    font-size: 18px;
}

.navbar ul li a:hover{
    color: #ffffff;
}

.title-m{
    font-size: 18px;
}

.logo{
    width: 55px;
    height: 55px;
    border-radius: 20%;
}

.qr-gif{
    width: -webkit-fill-available;
}

.handqr-code img{
    width: -webkit-fill-available;
    height: 600px;
    object-fit: contain;
}

.banner{
    background-color: #FFFFFF;
}

.intro{
    background-color: #F7F8F9;
    padding-top: 20px;
}

.pricing{
    background-color: #F7F8F9;
}

.accordion{
    --bs-accordion-bg: #F7F8F9 !important;
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: #F7F8F9 !important;
    box-shadow: none;
}

.accordion-body .qr-def{
    line-height: 28px;
}

.how-to{
    background-color: #ffffff;
}

.how-to-list ol li p{
    /* font-weight: 600; */
    font-size: 1.2rem;
    line-height: 1.43;
}

.Qr-gif img{
    width: -webkit-fill-available;
    height: 300px;
    object-fit: contain;
}

.sign-in-bg{
    background-color: #060A11;
}

.sign-email a{
    color: #2BBFBD;
    font-weight: 700;
}

.dashNavItems{
    display: flex;
    align-items: center;
  }
  
  .sideNavItems{
    color: #fff;
    cursor: pointer;
    font-weight: bold;
  }
  
  .sideNavItems:hover{
    color: #fff;
  }

  .terms {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.text-field{
    border-color: #2BBFBD;
}

.offcanvas.offcanvas-start {
    width: 250px !important;
}

.css-137ul40-MuiButtonBase-root-MuiIconButton-root {
    margin-left: 0 !important;
}

.qr-size{
    width: 50px;
    height: 50px;
}

.qr-size img{
    width: 100% !important;
    height: 100% !important;
    object-fit: contain !important;
}


.contact{
    background-color: #F7F8F9;
    height: auto;
    /* padding: 5rem 0; */
  }
  
  .contact .contact-form{
    /* background: linear-gradient(to right,  #FF5F6D, #FFC371); */
    background-color: #F7F8F9;
    height: auto;
    padding: 2rem;
    border-radius: 10px;
  }
  
  .contact .contact-form a{
    text-decoration: none;
    color: #060A11;
  }
  
  .contact .contact-form .shaking-hands img{
    /* border: 2px solid #060A11; */
    border-top-left-radius: 10px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .PhoneInputCountry{
    border: 1px solid #B9BFC4 !important;
    border-radius: 5px;
    padding: 5px;
  }
  
  .PhoneInputCountry select:focus{
    outline-color: #4F9AFF;
  }
  
  .phone-input input{
      border: 1px solid #B9BFC4;
      border-radius: 5px;
      padding: 16.5px 14px;
      font-size: 1rem;
  }
  
  .phone-input input:focus{
    outline-color: #4F9AFF;
  }

  .name h2{
    font-size: 2rem !important;
  }

  .name p{
    font-size: 10.9px;
  }

  .css-13cymwt-control{
    height: 58px !important;
  }

  .css-1nmdiq5-menu {
    background-color: #ffffff !important;
    z-index: 1000 !important;
    }

  @media screen and (min-width: 320px) and (max-width: 932px) {
    .w-25 {
        width: 100% !important;
    }
    @media (min-width: 576px){
        .container{
            max-width: -webkit-fill-available !important;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
    .w-25 {
        width: 50% !important;
    }
    @media (min-width: 576px){
        .container{
            max-width: -webkit-fill-available !important;
        }
    }
}

@media (orientation: landscape) {
    .container{
        --bs-gutter-x: 1.5rem;
        --bs-gutter-y: 0;
        width: 100% !important;
        padding-right: calc(var(--bs-gutter-x)* .5) !important;
        padding-left: calc(var(--bs-gutter-x)* .5) !important;
        margin-right: auto !important;
        margin-left: auto !important;
    }

    /* @media (min-width: 576px){
        .container{
            max-width: -webkit-fill-available !important;
        }
    } */
}

